<template>
  <div v-if="company">
    <CRow class="auto pb-4">
      <CCol>
        <div class="subheader">
          {{ company.name }}
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <h5>Update Time: {{ formatHour(company.post_update_hour) }}</h5>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RDataTable
          :items="properties"
          :fields="columns"
        >
          <template #name="{ item }">
            <td>
              <CLink :to="getLocation(item)">
                {{ item.name }}
              </CLink>
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { formatISO } from 'date-fns'

import { RDataTable } from '@/components/tables'
import { ReportingAPI } from '@/services/api/resources'

const columns = [
  {
    key: 'name',
    label: 'Property Name',
    _style: 'width: 20%'
  },
  {
    key: 'post_update_id',
    label: 'PKey',
    _style: 'width: 10%'
  },
  {
    key: 'integration_type',
    label: 'Integration Type',
    _style: 'width: 10%'
  },
  {
    key: 'total',
    label: 'Total Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'failed',
    label: 'Failed Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'cancelled',
    label: 'Cancelled Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'expired',
    label: 'Expired Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'successful',
    label: 'Successful Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'active',
    label: 'Active Tasks',
    _style: 'width: 10%'
  }
]
export default {
  name: 'PropertyList',
  components: {
    RDataTable
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      company: {
        name: '',
        post_update_hour: ''
      },
      properties: []
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.fetchCompanySummaryReport(this.$route.params.cid)
      }
    }
  },
  created () {
    this.columns = columns
    this.fetchCompanySummaryReport(this.$route.params.cid)
  },
  methods: {
    /**
     * Fetch the Price Updating summary report for this company.
     *
     * @param {Number} id - company id to fetch
     */
    async fetchCompanySummaryReport (id) {
      this.$emit('loading', true)
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)
      const response = await ReportingAPI.priceUpdating.companySummary({ id: id, params })
      if (response) {
        this.company = response.data.company
        this.properties = response.data.properties
      }
      this.$emit('loading', false)
    },
    /**
     * Format the Price Update Hour from 24h to 12h.
     *
     * @param {Number} hour
     */
    formatHour (hour) {
      var h = hour % 12
      if (h === 0) h = 12
      return h + ':00' + (hour < 12 ? 'am' : 'pm')
    },
    /**
     * Generate the route location object for navigating to
     * the property task list view.
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'PriceUpdatingPropertyReport',
        params: { cid: this.$route.params.cid, pid: row.id }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subheader {
  font-size: 1.2rem;
  color: $gray-600;
}
</style>
